import chinaMap from 'assets/json/china3.json'
import anhuiMap from 'assets/json/anhui.json'
import beijingMap from 'assets/json/beijing.json'
import chongqingMap from 'assets/json/chongqing.json'
import fujianMap from 'assets/json/fujian.json'
import gansuMap from 'assets/json/gansu.json'
import guangdongMap from 'assets/json/guangdong.json'
import guangxiMap from 'assets/json/guangxi.json'
import guizhouMap from 'assets/json/guizhou.json'
import hainanMap from 'assets/json/hainan.json'
import hebeiMap from 'assets/json/hebei.json'
import heilongjiangMap from 'assets/json/heilongjiang.json'
import henanMap from 'assets/json/henan.json'
import hkMap from 'assets/json/hk.json'
import hubeiMap from 'assets/json/hubei.json'
import hunanMap from 'assets/json/hunan.json'
import jiangsuMap from 'assets/json/jiangsu.json'
import jilinMap from 'assets/json/jilin.json'
import liaoningMap from 'assets/json/liaoning.json'
import moMap from 'assets/json/mo.json'
import neimengguMap from 'assets/json/neimenggu.json'
import ningxiaMap from 'assets/json/ningxia.json'
import qinghaiMap from 'assets/json/qinghai.json'
import shandongMap from 'assets/json/shandong.json'
import shanxiMap from 'assets/json/shanxi.json'
import shanxisMap from 'assets/json/shanxis.json'
import sichuanMap from 'assets/json/sichuan.json'
import taiwanMap from 'assets/json/taiwan.json'
import tianjingMap from 'assets/json/tianjing.json'
import xinjiangMap from 'assets/json/xinjiang.json'
import yunnanMap from 'assets/json/yunnan.json'
import zhejiangMap from 'assets/json/zhejiang.json'
import shanghaiMap from 'assets/json/shanghai.json'
import xizangMap from 'assets/json/xizang.json';
import jiangxiMap from 'assets/json/jiangxi.json';

const mapJsonArr = [
  {
    name: '安徽',
    json: anhuiMap
  },
  {
    name: '北京',
    json: beijingMap
  },
  {
    name: '重庆',
    json: chongqingMap
  },
  {
    name: '福建',
    json: fujianMap
  },
  {
    name: '甘肃',
    json: gansuMap
  },
  {
    name: '广东',
    json: guangdongMap
  },
  {
    name: '广西',
    json: guangxiMap
  },
  {
    name: '贵州',
    json: guizhouMap
  },
  {
    name: '海南',
    json: hainanMap
  },
  {
    name: '河北',
    json: hebeiMap
  },
  {
    name: '黑龙江',
    json: heilongjiangMap
  },

  {
    name: '河南',
    json: henanMap
  },
  {
    name: '香港',
    json: hkMap
  },
  {
    name: '湖北',
    json: hubeiMap
  },
  {
    name: '湖南',
    json: hunanMap
  },
  {
    name: '江苏',
    json: jiangsuMap
  },
  {
    name: '吉林',
    json: jilinMap
  },
  {
    name: '辽宁',
    json: liaoningMap
  },
  {
    name: '澳门',
    json: moMap
  },
  {
    name: '内蒙古',
    json: neimengguMap
  },
  {
    name: '宁夏',
    json: ningxiaMap
  },
  {
    name: '青海',
    json: qinghaiMap
  },
  {
    name: '山东',
    json: shandongMap
  },
  {
    name: '陕西',
    json: shanxiMap
  },
  {
    name: '山西',
    json: shanxisMap
  },
  {
    name: '四川',
    json: sichuanMap
  },
  {
    name: '台湾',
    json: taiwanMap
  },
  {
    name: '新疆',
    json: xinjiangMap
  },
  {
    name: '云南',
    json: yunnanMap
  },
  {
    name: '浙江',
    json: zhejiangMap
  },
  {
    name: '天津',
    json: tianjingMap
  },
  {
    name: '上海',
    json: shanghaiMap
  },
  {
    name: '西藏',
    json: xizangMap
  }, {
    name: '江西',
    json: jiangxiMap
  }
]
export default {
  mapJsonArr, chinaMap
}
