<template>
  <div class="page">
    <oil-header :headerInfo="headerInfo"></oil-header>
    <div class="wrapper">
      <div class="leftFrame">
        <div class="machineSpread">
          <oil-title>
            <span slot="left">{{ provinceName }}设备分布</span>
            <span slot="right">单位：台</span>
          </oil-title>
          <oil-spread-bar-chart
            ref="spreadDevice"
            v-if="flag.spreadDevice"
            :deviceInfo="spreadDeviceInfo"
          ></oil-spread-bar-chart>
        </div>
        <machine-amount :deviceInfo="deviceOnOffInfo"></machine-amount>
        <machine-warning-table
          :alarmInfo="alarmInfo"
          :alarmPx="alarmPx"
        ></machine-warning-table>
      </div>
      <div class="middleFrame">
        <!-- 油田地图 -->
        <oil-machine-map
          ref="machineMap"
          v-if="flag.map"
          :mapInfo="mapInfo"
          :pieces="pieces"
          @setProvince="setProvince"
          @initGlobal="initGlobal"
        ></oil-machine-map>
        <div class="machineOilProd">
          <div class="left">
            <div class="title">
              <p class="tit">{{ provinceName }}采液量及能耗</p>
              <p class="unit">采液量单位：立方米<span>能耗单位：kWh</span></p>
            </div>
            <total-oil-prod-chart
              ref="totalDevice"
              v-if="flag.totalDevice"
              :deviceInfo="totalDeviceInfo"
            ></total-oil-prod-chart>
          </div>
          <div class="right">
            <oil-title>
              <span slot="left">{{ provinceName }}7日采液量及能耗 </span>
              <span slot="rightwarp">采液量单位：立方米能耗单位：kWh</span>
            </oil-title>
            <history-oil-prod-chart
              ref="historyProdPower"
              v-if="flag.historyProdPower"
              :historyInfo="historyProdPowerInfo"
            ></history-oil-prod-chart>
          </div>
        </div>
      </div>
      <div class="rightFrame">
        <div class="realtimeOilProd">
          <oil-title>
            <span slot="left">{{ provinceName }}实时采液量</span>
            <span slot="right">单位：立方米</span>
          </oil-title>
          <realtime-oil-prod-chart
            ref="realTimeProd"
            v-if="flag.realTimeProd"
            :deviceInfo="realTimeProdInfo"
          ></realtime-oil-prod-chart>
        </div>
        <div class="machineDailyOnlineRate">
          <oil-title>
            <span slot="left">{{ provinceName }}日设备上线率</span>
            <span slot="right">单位：百分比%</span>
          </oil-title>
          <oil-rate-bar-chart
            ref="onlineDevice"
            v-if="flag.onlineDevice"
            :deviceInfo="onlineDeviceInfo"
          ></oil-rate-bar-chart>
        </div>
        <div class="machineDailyOnlineRate">
          <oil-title>
            <span slot="left">{{ provinceName }}设备故障率</span>
            <span slot="right">单位：百分比%</span>
          </oil-title>
          <oil-rate-bar-chart
            ref="faultDevice"
            v-if="flag.faultDevice"
            :deviceInfo="faultDeviceInfo"
          ></oil-rate-bar-chart>
        </div>
        <div class="oilNewsLetter">
          <oil-title>
            <span slot="left">{{ provinceName }}生产简讯</span>
            <span slot="right">实时更新</span>
          </oil-title>
          <oil-newsletter
            v-if="flag.message"
            :messageInfo="messageInfo"
          ></oil-newsletter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OilHeader from 'components/oilHeader/OilHeader.vue'
import OilTitle from 'components/oilTitle/OilTitle.vue'
import OilSpreadBarChart from 'components/oilBarChart/OilSpreadBarChart.vue'
import MachineAmount from 'components/machineAmount/MachineAmount.vue'
import MachineWarningTable from 'components/oilTable/MachineWarningTable.vue'
import OilMachineMap from 'components/oilMachineMap/OilMachineMap.vue'
import TotalOilProdChart from 'components/oilPieChart/TotalOilProdChart.vue'
import HistoryOilProdChart from 'components/oilBarChart/HistoryOilProdChart.vue'
import RealtimeOilProdChart from 'components/oilBarChart/RealtimeOilProdChart.vue'
import OilRateBarChart from 'components/oilBarChart/OilRateBarChart.vue'
import OilNewsletter from '../../components/oilNewsletter/OilNewsletter.vue'

import nationalMachineJs from 'network/machine/nationalMachine.js'

export default {
  name: 'NationalMachine',
  components: {
    OilHeader,
    OilTitle,
    OilSpreadBarChart,
    MachineAmount,
    MachineWarningTable,
    OilMachineMap,
    TotalOilProdChart,
    HistoryOilProdChart,
    RealtimeOilProdChart,
    OilRateBarChart,
    OilNewsletter
  },
  data: () => {
    return {
      headerInfo: {
        name: '智慧抽油监控系统'
      },
      provinceName: '地区',
      flag: {
        spreadDevice: false,
        historyProdPower: false,
        realTimeProd: false,
        onlineDevice: false,
        faultDevice: false,
        totalDevice: false,
        map: false,
        message: false
      },
      timer: {
        fiveS: null,
        province: '全国'
      },
      spreadDeviceInfo: {},
      alarmInfo: [],
      alarmPx: 0,
      historyProdPowerInfo: {},
      realTimeProdInfo: {},
      onlineDeviceInfo: {},
      faultDeviceInfo: {},
      totalDeviceInfo: {},
      deviceOnOffInfo: {},
      mapInfo: [],
      pieces: [],
      geoCoordMapInfo: {},
      messageInfo: { location: '全国', arr: [] }
    }
  },
  methods: {
    /**
     * 事件监听
     */

    // 全国信息初始化
    initGlobal() {
      this.provinceName = '地区'
      this.timer.province = '全国'
      this.messageInfo.location = '全国'
      this.getAreaGlobalMap()
      this.getAreaDeviceCount('全国')
      this.getAreaAlarm('全国')
      this.getAreaTotalProd('全国')
      this.getAreaHistoryProdPower('全国')
      this.getAreaDeviceProd('全国')
      this.getAreaDeviceOnline('全国')
      this.getAreaDeviceFault('全国')
      this.getAreaMessage('全国')
    },
    // 省级信息初始化
    setProvince(v) {
      this.provinceName = v.provinceNickName
      this.timer.province = v.provinceNickName
      this.messageInfo.location = v.provinceNickName
      this.deviceOnOffInfo = v
      this.getAreaDeviceCount(v.province)
      this.getAreaAlarm(v.province)
      this.getAreaTotalProd(v.province)
      this.getAreaHistoryProdPower(v.province)
      this.getAreaDeviceProd(v.province)
      this.getAreaDeviceOnline(v.province)
      this.getAreaDeviceFault(v.province)
      this.getAreaMessage(v.province)
    },
    // 定时获取5s
    fiveSecondsTimer() {
      this.timer.fiveS = setInterval(() => {
        this.getAreaAlarm(this.timer.province)
      }, 1000 * 5)
    },
    /**
     * 网络请求
     */
    // 获取地区设备分布
    getAreaDeviceCount(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaDeviceCount(params).then((res) => {
        if (res.code == 0) {
          this.spreadDeviceInfo = res.device_info
          this.flag.spreadDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取故障警报信息
    getAreaAlarm(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaAlarm(params).then((res) => {
        if (res.code == 0) {
          this.alarmInfo = res.alarm_info
          this.alarmPx = res.alarm_info.length * 28
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取年月日采液量及能耗
    getAreaTotalProd(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaTotalProd(params).then((res) => {
        if (res.code == 0) {
          this.totalDeviceInfo = {
            yearInfo: res.year_info,
            monthInfo: res.month_info,
            dayInfo: res.day_info
          }
          this.flag.totalDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区7日采液量及能耗
    getAreaHistoryProdPower(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaHistoryProdPower(params).then((res) => {
        if (res.code == 0) {
          this.historyProdPowerInfo = res.history_info
          this.flag.historyProdPower = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区实时采液量
    getAreaDeviceProd(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaDeviceProd(params).then((res) => {
        if (res.code == 0) {
          this.realTimeProdInfo = res.device_info
          this.flag.realTimeProd = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区日设备上线率
    getAreaDeviceOnline(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaDeviceOnline(params).then((res) => {
        if (res.code == 0) {
          const data = {
            type: '正确率',
            area: res.device_info.area,
            rate: res.device_info.online
          }
          this.onlineDeviceInfo = data
          this.flag.onlineDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取地区设备故障率
    getAreaDeviceFault(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaDeviceFault(params).then((res) => {
        if (res.code == 0) {
          const data = {
            type: '故障率',
            area: res.device_info.area,
            rate: res.device_info.fault
          }
          this.faultDeviceInfo = data
          this.flag.faultDevice = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取全国设备数地图
    getAreaGlobalMap() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id
      }
      nationalMachineJs.areaGlobalMap(params).then((res) => {
        if (res.code == 0) {
          this.deviceOnOffInfo = {
            total: res.total,
            onlineTotal: res.online_total,
            offLineTotal: res.total - res.online_total,
            onlinePercent: res.online_percent,
            // onlineRate:
            //   res.online_total == 0
            //     ? 0
            //     : (res.online_total / res.total).toFixed(2)
          }
          this.mapInfo = res.global_map_info
          this.pieces = res.pieces
          this.flag.map = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取生产简讯
    getAreaMessage(province) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: province
      }
      nationalMachineJs.areaMessage(params).then((res) => {
        if (res.code == 0) {
          this.messageInfo.arr = res.message_info
          this.flag.message = true
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
    /**
     * 其它方法
     */
  },
  mounted() {
    this.initGlobal()
    this.getAreaMessage('全国')
    // 定时器
    this.fiveSecondsTimer()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  },
  beforeDestroy() {
    window.clearInterval(this.timer.fiveS)
    this.timer.fiveS = null
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';

.page {
  width: 100%;
  min-height: 100vh;
  min-width: 1200px;
  background: url('~assets/img/common/bg.png');
  background-size: cover;
  .wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
  }
  .leftFrame {
    margin: 19px 0 25px 19px;
    width: 386px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .middleFrame {
    margin: 0 0 25px 13px;
    .machineOilProd {
      width: 1076px;
      height: auto;
      display: flex;
      justify-content: space-around;
      .left {
        > .title {
          width: 656px;
          height: 43px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          letter-spacing: 1px;
          background: url('~assets/img/common/title2.png');
          background-size: cover;
          .tit {
            margin-left: 69px;
            width: auto;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f9fafa;
            line-height: 11px;
          }
          .unit {
            width: auto;
            height: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(249, 250, 250, 0.64);
            line-height: 11px;
            span {
              margin-left: 20px;
            }
          }
        }
      }

      .right {
      }
      ::v-deep {
        .historyOilProdChart {
          margin-top: 11px;
          width: 400px;
          height: 303px;
        }
        .oilTitleContainer > .title {
          width: 400px;
           .rightwarp{
             width: 140px;
           }
        }
      }
    }
  }
  .rightFrame {
    margin: 0 0 25px 14px;
    width: 386px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .machineDailyOnlineRate {
      margin-top: 11px;
    }
    .oilNewsLetter {
      margin-top: 11px;
    }
  }
}
</style>
