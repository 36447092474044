<template>
  <div class="oilMachineMapContainer">
    <div class="backBtn" v-show="visable" @click="backTopMap">
      <img :src="backImg" alt="back" />
      <button type="button">返回上一级</button>
    </div>
    <div class="oilMachineMap" ref="oilMachineMap"></div>
  </div>
</template>

<script>
import getMapJson from 'assets/js/getMapJson.js'
import commonJS from 'assets/js/common.js'
import nationalMachineJs from 'network/machine/nationalMachine.js'
export default {
  name: 'OilMachineMap',
  props: {
    mapInfo: Array,
    pieces: Array
  },
  data: () => {
    return {
      chartInstance: null,
      visable: false,
      provinceName: '',
      mapName: getMapJson.chinaMap,
      mapZoom: 1.75,
      roam: 'move',
      top: '30.5%',
      left: '30%',
      symbol:
        'image://' + 'http://pumpscreen.crrcrd.com/static/map-pointer.png',
      backImg: require('../../assets/img/common/back.png'),
      geoCoordMap: [
        // 必须要初始化
        {
          longitude: '',
          latitude: '',
          name: '',
          nickname: '',
          total: '',
          address: ''
        }
      ]
    }
  },
  watch: {
    mapInfo() {
      this.updateChart()
    },
    pieces() {
      this.updateChart()
    },
    mapName() {
      this.updateChart()
    }
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.oilMachineMap)
      this.chartShowLoading()
      this.chartEvent()
      this.updateChart()
    },
    // 添加设备地理位置
    addMachineGeo() {},
    // 返回顶层地图
    backTopMap() {
      this.$emit('initGlobal')
      this.geoCoordMap = []
      this.mapZoom = 1.75
      this.roam = 'move'
      this.top = '30.5%'
      this.left = '30%'
      this.mapName = getMapJson.chinaMap
      this.updateChart()
      this.visable = false
    },
    // 进入省级地图
    chartEvent() {
      this.chartInstance.off('click')
      this.chartInstance.on('click', (e) => {
        this.visable = true
        const chooseName = getMapJson.mapJsonArr.filter((item) => {
          return item.name == e.name
        })
        // 监听油田点击事件
        if (e.seriesType == 'scatter') {
          this.$router.push({
            path: '/OilField',
            query: {
              name: e.data.name,
              id: e.data.id
            }
          })
        }
        if (e.seriesType == 'map') {
          this.mapZoom = 1.2
          this.roam = false
          // if (chooseName[0].name == '新疆') {
          //   this.top = '95%'
          //   this.left = '80%'
          // }
          commonJS.translateMap(chooseName[0].name, this)
          this.getprovinceMap(chooseName)
        }
      })
    },
    // 更新图表
    updateChart() {
      this.$echarts.registerMap('chinaMap', this.mapName)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}'
        },
        geo: {
          type: 'map',
          map: 'chinaMap', // chinaMap需要和registerMap中的第一个参数保持一致
          roam: this.roam, // 设置允许缩放以及拖动的效果
          aspectScale: 0.75,
          zoom: this.mapZoom,
          top: this.top,
          left: this.left,
          backgroundColor: 'transparent',
          label: {
            normal: {
              show: true,
              color: '#FFFF',
              fontSize: 14,
              fontFamily: 'Source Han Sans CN',
              fontWeight: 'bold'
            },
            emphasis: {
              textStyle: {
                color: '#fff'
              }
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#94b1c5', // 地区颜色
              borderColor: '#45C3F2',
              borderWidth: 1
            },
            emphasis: {
              borderWidth: 0
            }
          }
        },
        series: [
          {
            data: this.mapInfo,
            geoIndex: 0, // 将空气质量的数据和第0个geo配置关联在一起
            type: 'map'
          },
          {
            // 根据经纬度在地图上描点
            type: 'scatter',
            coordinateSystem: 'geo',
            // animation: true,
            // rippleEffect: {
            //   brushType: 'stroke'
            // },
            // symbolSize: function (val, params) {
            //   return 30
            // },
            symbol: this.symbol, // 标记图
            symbolSize: [40, 29],
            data: this.geoCoordMap.map((item) => {
              return {
                id: item.id,
                name: item.name,
                nick: item.nickname,
                total: item.total,
                value: [item.longitude, item.latitude],
                address: item.address
              }
            }),
            tooltip: {
              formatter: (params) => {
                const tipHtml = `  <div style="width: 274px;height: 157px;background: #000616; border: 1px solid ;">
    <p
      style=" margin: 28px auto 17px auto;width: 90%;height:16px; font-size: 16px;font-family: Source Han Sans CN;color: #FFFFFF;font-weight: bold;">
      ${params.data.name}</p>
    <p
      style=" margin: 0 auto 14px auto; width:90%; height: 16px; font-size: 14px;font-family: Source Han Sans CN;color: #00FEE5;">
      油井数：<span style="font-size: 20px; font-weight: bold;">${params.data.total}</span></p>
    <p
      style="margin: 0 auto 0 auto;width:90%; height: 40px; font-size: 14px;font-family: Source Han Sans CN;color: #00FEE5;">
      地 址：${params.data.address}</p>
  </div>`
                return tipHtml
              }
            }
          }
        ],
        visualMap: {
          min: 0,
          max: 1000000,
          left: 0,
          seriesIndex: 0,
          type: 'piecewise',
          bottom: 37,
          itemGap: 25,
          textStyle: {
            color: '#FFFF',
            fontSize: 14,
            fontFamily: 'Source Han Sans CN',
            fontWeight: 'bold'
          },
          pieces: this.pieces
          // pieces: [
          //   {
          //     gt: 2000,
          //     color: '#0D5AB2',
          //     label: '2000以上'
          //   },
          //   {
          //     gt: 1500,
          //     lte: 2000,
          //     color: '#3974B8',
          //     label: '1500-2000'
          //   },
          //   {
          //     gt: 1000,
          //     lte: 1500,
          //     color: '#428ECC',
          //     label: '1000-1500'
          //   },
          //   {
          //     gt: 500,
          //     lte: 1000,
          //     color: '#5E97DE',
          //     label: '500-1000'
          //   },
          //   { gte: 0, lte: 500, color: '#7A9FCD', label: '0-500' }
          // ]
        }
      }

      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option, true)
    },
    // 获取省级设备地图信息及标记点
    getprovinceMap(chooseName) {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        province: chooseName[0].name
      }
      nationalMachineJs.provinceMap(params).then((res) => {
        if (res.code == 0) {
          const name = this.transformProvinceName(chooseName[0].name)
          const province = {
            province: chooseName[0].name,
            provinceNickName: name,
            total: res.total,
            onlineTotal: res.online_total,
            offLineTotal: res.total - res.online_total,
            onlinePercent: res.online_percent,
            // onlineRate:
            //   res.online_total == 0
            //     ? 0
            //     : (res.online_total / res.total).toFixed(2)
          }
          // 进入省级地图，通知父组件更改
          this.$emit('setProvince', province)
          this.geoCoordMap = res.map_info
          this.mapName = chooseName[0].json
          this.provinceName = chooseName[0].name
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('oilMachineMap'))
          .resize()
      }
    },
    // 省级名字转换
    transformProvinceName(name) {
      switch (name) {
        case '新疆维吾尔自治区':
          name = '新疆'
          break
        case '宁夏回族自治区':
          name = '宁夏'
          break
        case '内蒙古自治区':
          name = '内蒙古'
          break
        case '西藏自治区':
          name = '西藏'
          break
        case '广西壮族自治区':
          name = '广西'
          break
        default:
          break
      }
      return name
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  created() {}
}
</script>
<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.oilMachineMapContainer {
  position: relative;
  display: flex;
  width: 1076px;
  height: 613px;
  .backBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0px;
    display: flex;
    width: auto;
    height: auto;
    cursor: pointer;
    img {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }
    button {
      width: auto;
      height: 14px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #579cf3;
      background: transparent;
      border: none;
      &:active {
        color: rgba(87, 156, 243, 0.7);
      }
    }
  }
  .oilMachineMap {
    margin-left: 10px;
    width: 1066px;
    height: 613px;
  }
}
</style>
